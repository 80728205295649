<template>
    <div class="container-fluid">
        <div class="row h-100">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <img class="img-fluid" src="@/assets/darmin_plus_full.svg" alt="" />
            </div>
        </div>
    </div>
</template>

<style scoped>
img{
    max-height: calc(100vh - 120px);
}
</style>
